<template>
  <auth>
    <a-row
      type="flex"
      justify="center"
      style="height: 100vh;"
      class="ant-row-flex bg"
    >
      <a-col :lg="10" :md="8" :sm="12" :xs="18" :xl="8" :xxl="6" class="mt">
        <a-alert
          v-if="errors"
          type="error"
          message="Email ou mot de passe erroné"
          style="margin-bottom: 30px;"
          banner
        />
        <a-form
          @submit="handleSubmit"
          ref="inscription"
          :form="form"
          style="background-color: #ffffffa1; padding: 5%;"
        >
          <h1 style="text-align: center;">Demande d'inscription</h1>

          <a-form-item v-bind="formItemLayout">
            <a-input
              placeholder="Nom"
              class="upper_case"
              v-decorator="[
                'nom_fr',
                {
                  rules: [
                    { required: true, message: 'Nom est obligatoire!' },
                    {
                      pattern: /^[A-Za-z ]{3,}$/,
                      message: 'Le format du nom n\'est pas valide ! ',
                    },
                    { transform: nomToUpperCase },
                  ],
                },
              ]"
            >
              <a-icon
                slot="prefix"
                type="user"
                style="color: rgba(0, 0, 0, 0.25);"
              />
            </a-input>
          </a-form-item>
          <a-form-item v-bind="formItemLayout">
            <a-input
              placeholder="Prénom"
              class="upper_case"
              v-decorator="[
                'prenom_fr',
                {
                  rules: [
                    { required: true, message: 'Prénom est obligatoire!' },
                    {
                      pattern: /^[A-Za-z ]{3,}$/,
                      message: 'Le format du prénom n\'est pas valide !',
                    },
                    { transform: prenomToUpperCase },
                  ],
                },
              ]"
            >
              <a-icon
                slot="prefix"
                type="user"
                style="color: rgba(0, 0, 0, 0.25);"
              />
            </a-input>
          </a-form-item>
          <a-row
            type="flex"
            justify="space-around"
            align="middle"
            class="ant-row-flex"
          >
            <a-col :md="5" :xs="20">
              <a-form-item v-bind="formItemLayout">
                <a-select
                  v-decorator="[
                    'day',
                    {
                      rules: [
                        { required: true, message: 'Le jour est obligatoire!' },
                        { validator: validateDtn },
                      ],
                    },
                  ]"
                  placeholder="Jour"
                  style="width: 100%; margin-right: 1%;"
                >
                  <a-select-option v-for="day in days" :key="day.id"
                    >{{ day.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" :xs="20">
              <a-form-item v-bind="formItemLayout">
                <a-select
                  v-decorator="[
                    'month',
                    {
                      rules: [
                        { required: true, message: 'Le mois est obligatoire!' },
                      ],
                    },
                  ]"
                  placeholder="Mois"
                  style="width: 100%; margin-right: 1%;"
                >
                  <a-select-option v-for="month in months" :key="month.id"
                    >{{ month.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" :xs="20">
              <a-form-item v-bind="formItemLayout">
                <a-select
                  v-decorator="[
                    'year',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'L\'année est obligatoire!',
                        },
                      ],
                    },
                  ]"
                  placeholder="Année"
                  style="width: 100%; margin-right: 1%;"
                >
                  <a-select-option
                    v-for="(item, index) in getYears()"
                    :key="item"
                    >{{ item }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" :xs="20">
              <a-form-item
                v-bind="{
                  labelCol: {
                    xs: { span: 4 },
                    sm: { span: 4 },
                  },
                  wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                  },
                }"
              >
                <a-checkbox v-decorator="['is_presumer']">Présumé(e)</a-checkbox>
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item v-bind="formItemLayout">
            <a-date-picker
              :format="dateFormat"
              :disabledDate="disabledFutureDates"
              placeholder="Date d'installation"
              :showToday="false"
              style="width: 100%;"
              v-decorator="[
                'dt_autorisation',
                {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'La date d\'installation est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout">
            <a-input
              placeholder="Email"
              v-decorator="[
                'email',
                {
                  rules: [
                    { required: true, message: 'Email est obligatoire!' },
                    {
                      pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                      message: 'Le format de l\'email n\'est pas valide ',
                    },
                  ],
                },
              ]"
            >
              <a-icon
                slot="prefix"
                type="mail"
                style="color: rgba(0, 0, 0, 0.25);"
              />
            </a-input>
          </a-form-item>
          <a-form-item v-bind="formItemLayout">
            <a-input
              placeholder="Mot de passe"
              :type="inputType"
              v-decorator="[
                'password',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Mot de passe est obligatoire!',
                    },
                    {
                      pattern: /(?=.*\d.*)(?=.*[a-zA-Z].*).{8,20}/,
                      message:
                        ' Utilisez au moins 8 caractères avec des lettres, des chiffres pour votre mot de passe !',
                    },
                    { validator: this.validateToNextPassword },
                  ],
                },
              ]"
            >
              <a-icon
                slot="prefix"
                type="lock"
                style="color: rgba(0, 0, 0, 0.25);"
              />
              <a-icon
                slot="addonAfter"
                type="eye"
                @click="changeInputType"
                style="color: rgba(0, 0, 0, 0.25);"
              />
            </a-input>
          </a-form-item>
          <a-form-item v-bind="formItemLayout">
            <a-input
              :type="inputType"
              @blur="handleConfirmBlur"
              placeholder="Confirmer mot de passe"
              v-decorator="[
                'password_confirmation',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Confirmation Mot de passe est obligatoire!',
                    },
                    {
                      validator: compareToFirstPassword,
                    },
                  ],
                },
              ]"
            >
              <a-icon
                slot="prefix"
                type="lock"
                style="color: rgba(0, 0, 0, 0.25);"
              />
              <a-icon
                slot="addonAfter"
                type="eye"
                @click="changeInputType"
                style="color: rgba(0, 0, 0, 0.25);"
              />
            </a-input>
          </a-form-item>

          <a-form-item v-bind="tailFormItemLayout">
            <a-spin :spinning="spinning">
              <a-button
                type="primary"
                class="regiter-form-button"
                htmlType="submit"
                >{{ spinning ? "Inscription..." : "Inscription" }}</a-button
              >
            </a-spin>
          </a-form-item>

          <p style="font-weight: 600;">
            Vous avez déjà un compte ?
            <router-link
              :to="{ name: 'adherent_login' }"
              style="color: #03a9f4;"
              >Connexion</router-link
            >
          </p>
        </a-form>
      </a-col>
      <a-col :lg="24" :md="24" :sm="24" :xs="24">
   
    <a-row
          type="flex"
          justify="space-between"
           align="bottom"
           class="full-height"
    >
    </a-row>
      </a-col>
    </a-row>
  </auth>   
</template>

<script>
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
};
const formItemLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};
const datesItemLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 8 },
    sm: { span: 8 },
  },
};
const MAX_AGE = 90;
const MIN_AGE = 19;
const HTTP_VALIDATION_ERROR = 422
const VERIFY_MAIL_URL = "/registration_success";
import Auth from "@/components/common/AuthParent";
import _range from "lodash-es/range";
import _forEach from "lodash-es/forEach";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "AdherentRegisterPage",
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  created(){
      this.$store.cache.dispatch("fetchMessageCommunication");
  },
  components: {
    Auth
  },
  data() {
    return {
      confirmDirty: false,
      errors: false,
      registering: false,
      spinning: false,
      inputType: "password",
      tailFormItemLayout,
      formItemLayout,
      datesItemLayout,
      months: [
        { id: 1, name: "Janvier" },
        { id: 2, name: "Février" },
        { id: 3, name: "Mars" },
        { id: 4, name: "Avril" },
        { id: 5, name: "Mai" },
        { id: 6, name: "Juin" },
        { id: 7, name: "Juillet" },
        { id: 8, name: "Aout" },
        { id: 9, name: "Septembre" },
        { id: 10, name: "Octobre" },
        { id: 11, name: "Novembre" },
        { id: 12, name: "Décembre" },
      ],
      days: [
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 3, name: "3" },
        { id: 4, name: "4" },
        { id: 5, name: "5" },
        { id: 6, name: "6" },
        { id: 7, name: "7" },
        { id: 8, name: "8" },
        { id: 9, name: "9" },
        { id: 10, name: "10" },
        { id: 11, name: "11" },
        { id: 12, name: "12" },
        { id: 13, name: "13" },
        { id: 14, name: "14" },
        { id: 15, name: "15" },
        { id: 16, name: "16" },
        { id: 17, name: "17" },
        { id: 18, name: "18" },
        { id: 19, name: "19" },
        { id: 20, name: "20" },
        { id: 21, name: "21" },
        { id: 22, name: "22" },
        { id: 23, name: "23" },
        { id: 24, name: "24" },
        { id: 25, name: "25" },
        { id: 26, name: "26" },
        { id: 27, name: "27" },
        { id: 28, name: "28" },
        { id: 29, name: "29" },
        { id: 30, name: "30" },
        { id: 31, name: "31" },
      ],
    };
  },
  computed :{
    ...mapGetters({getSuccessMessage : 'getDemandeInscriptinSuccessMessage'})
  },
  methods: {
    getYears() {
      return _range(this.getMinAge(), this.getMaxAge());
    },
    getMinAge() {
      return this.now("YYYY") - MAX_AGE;
    },
    getMaxAge() {
      return this.now("YYYY") - MIN_AGE;
    },
    handleSubmit(e) {
      e.preventDefault();
      const form = this.form;
      let fields = form.getFieldsValue();

      form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.spinning = true;
          let user = {};
          for (const key in fields) {
            if (fields.hasOwnProperty(key)) {
              user[key] = fields[key];
            }
          }
          user.dtn = `${fields["year"]}/${fields["month"]}/${fields["day"]}`;
            user.dt_autorisation = this.formatDate(fields["dt_autorisation"], "YYYY/MM/DD");

          this.register(user)
            .then(() => this.$router.push(VERIFY_MAIL_URL))
            .catch((err) => {
              this.spinning = false;
              this.displayValidationErrors(err);
            });
        }
      });
    },
    changeInputType() {
      this.inputType = this.inputType === "password" ? "text" : "password";
    },
    displayValidationErrors(error) {
      let _this = this;
      if (this.get(error,'response.status') === HTTP_VALIDATION_ERROR) {
        let er = error.response.data.errors;
        _forEach(er, function (value, key) {
          _forEach(value, function (value, key) {
            _this.$_throwAnError(value);
          });
        });
      }
    },
    displayErrors(state) {
      this.errors = state;
    },
    nomToUpperCase(value) {
      if (value) {
        this.form.setFieldsValue({
          nom_fr: value.toUpperCase(),
        });
      }
    },
    prenomToUpperCase(value) {
      if (value) {
        this.form.setFieldsValue({
          prenom_fr: value.toUpperCase(),
        });
      }
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback(
          "Les deux mots de passe ne sont pas identiques !"
        );
        return;
      }
      callback();
    },
    validateDtn(rule, value, callback) {
      const form = this.form;
      let day = form.getFieldValue("day");
      let month = form.getFieldValue("month");
      let year = form.getFieldValue("year");
      let dt = moment(`${year}-${month}-${day}`);

      if (day && month && year && !dt.isValid()) {
        callback("Le jour est invalide!");
        return;
      }
      callback();
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(["password_confirmation"], {force: true});
      }
      callback();
    },
    ...mapActions(["register"]),
  },
};
</script>
<style scoped>
  .regiter-form-button {
    width: 100%;
  }
  .upper_case {
  text-transform: uppercase;
  }
  .full-height{
    height : 100%
  }
  .mt{
    margin-top: 1%;
  }
</style>
